
import { defineComponent, onMounted, reactive } from "vue";
import * as moment from "moment";
import mAxiosApi from "@/api";
import XLSX from "xlsx";
import { ElNotification } from 'element-plus'
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "contrats",
  components: {
    //
  },
 
  emits: ["refreshRouter"],

  setup(props, { emit }) {

    //moment.default.locale("fr");

    const state = reactive({
      etape: 0,
      workbook: [] as any,
      okList : false,
      totIncoherence : 0 as number,
      lConst : [] as any,
      loaderUpload : false,
    });
    const { t } = useI18n()

    const submitUpload = () => {
      state.loaderUpload = true;
      const file1: any = document.querySelector('input[type=file]');
      const file = file1.files[0];
      const reader = new FileReader();
      
      reader.addEventListener("load", function () {
        //console.log(reader.result);
        
        const data = new Uint8Array(reader.result as ArrayBuffer);
        const zWorkbook = XLSX.read(data, {type: 'array'});
        
        if (zWorkbook['SheetNames'][1]  != "Contrats") {
          alert('erreur');
          state.loaderUpload = false;
          return false;
        }
        
        state.workbook = to_json(zWorkbook)['Contrats'];
        delete state.workbook[0];
        test_coherence();

      }, false);

      if (file) {
        reader.readAsArrayBuffer(file);
      }

    }

    const excelToDate = (excelDate) => {
      const mDate = new Date(Math.round((excelDate - (25567 + 2)) * 86400 * 1000));
      if (isNaN(mDate.getTime())) {
        return '';
      } 
      const convertedDate = mDate.toISOString().split('T')[0];
      return moment.default(convertedDate).format("DD.MM.YYYY");
    }

    const to_json = function to_json(workbook) {
      var result = {};
      workbook.SheetNames.forEach(function(sheetName) {
        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header:1});
        if(roa.length) result[sheetName] = roa;
      });
      return result;
    };


    const test_coherence = () => {
      state.totIncoherence = 0;
      state.workbook = state.workbook.filter(item => item.length > 0);
      let totIncoherence = 0;

      state.workbook.forEach(function(item) {
        item[17] = [] as any;       

        item[17].push({0: item[0] === undefined ? t("N° de contrat vide") : null});  
        item[17].push({0: null});
        item[17].push({0: item[2] === undefined ? t("Aucun constructeur") : null});

        if (item[17][2][0] === null) {
          const allConst: [] = state.lConst.results;
          const filterConst = allConst.filter((it: any) => it.soc_nom_societe.toUpperCase() == item[2].toUpperCase());
          if (filterConst.length == 0) item[17][2][0] = item[2] + " inconnu";
        }

        item[17].push({0: item[3] === undefined ? t("Référence vide") : null});
        item[17].push({0: item[4] === undefined ? t("Aucune description") : null});
        item[17].push({0: item[5] === undefined ? t("N° de série vide") : null});
        item[17].push({0: null});
        item[17].push({0: null});
        item[17].push({0: null});
        item[17].push({0: item[9] === undefined || excelToDate(item[9]) === '' ? t("Date de début vide") : null});
        item[17].push({0: item[10] === undefined || excelToDate(item[10]) === '' ? t("Date de fin vide") : null});

        if (item[9] > item[10]) item[17][10] = {0: 'Début > Fin'}

        item[18] = totDefaut(item[17]);
        totIncoherence = totIncoherence + item[18];

      });

      state.totIncoherence = totIncoherence;
      state.loaderUpload = false;
      state.okList = true;
      state.etape = 1;

    };

    const totDefaut = (mArray) => {
      let test = 0;
      mArray.forEach(function(item) {
        if(item[0]) test += 1;
      });
      // if( test > 0 ) {
        // state.totIncoherence = Number(state.totIncoherence) + Number(test);
      //}
      return test;
    }

    const importContrat = async () => {
      state.etape += 1;
      const contratSelect: any = state.workbook;
      mAxiosApi
        .post("importContrat", { contratSelect })
        .then(() => {

          ElNotification({
            title: t("Succès"),
            message: t("Import de vos contrats réalisé avec succès !"),
            type: 'success',
          })

          emit("refreshRouter" , 1);
        })
        .catch((err) => {
          console.error(err);
        });
    };


    onMounted(async () => {
      state.lConst = await mAxiosApi.prototype.getAxios("/getConstList");
      // console.log('Contructeur', myList);
    });


    return {
      state,
      submitUpload,
      excelToDate,
      test_coherence,
      totDefaut,
      importContrat,
    };
  
  },
});
