<template>
  <transition name="fade">
    <div>

      <div class="card mt-0">
        <div class="card-body">
          <el-steps :active="state.etape" align-center>
            <el-step :title="$t('Upload')" :description="$t('Envoyer la matrice')" />
            <el-step :title="$t('Vérification')" :description="$t('Test de cohérence du fichier')" />
            <el-step :title="$t('Traitement')" :description="$t('Import des données')" />
          </el-steps>
        </div>
      </div>

      <div class="card mt-10">
        <div class="card-body pt-0">

          <div class="card-header" id="kt_activities_header" v-if="state.etape == 1">
              <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
                  <h4 style="margin-top: 7px;">
                    <span class="text-gray-700 fs-2" style="margin-top: 8px;">{{$t("Vos données de contrats à importer")}}</span>
                    <span v-if="state.totIncoherence>0" class="text-danger" style="margin-top: 8px;margin-left: 10px;">{{$t("Attention,")}} {{ state.totIncoherence }} {{$t("incohérences détéctées, veuillez les corriger avant de continuer, svp.")}}</span>
                    <span v-else class="text-success" style="margin-top: 8px;margin-left: 10px;">{{$t("Votre fichier semble valide, vous pouvez dés à présent lancer l'import.")}}</span>
                  </h4>
              </div>
              <div class="card-toolbar">

                  <button type="button" class="btn btn-text-icon btn-secondary btn-active-light m-1" style="margin-top: 9px !important; height: 44px;" v-if="state.etape==1" @click="state.etape -= 1">
                    <span class="svg-icon svg-icon-1 svg-icon-dark"><inline-svg src="media/icons/duotune/arrows/arr022.svg" /></span>
                    <span>{{$t("Retour")}}</span>
                  </button>
                  <button v-if="state.etape==1 && state.totIncoherence==0" type="button" class="btn btn-text-icon btn-primary m-1" style="margin-top: 9px !important; height: 44px;"  @click="importContrat">
                    <span>{{$t("Importer la liste de contrat")}}</span>
                    <span class="svg-icon svg-icon-1 svg-icon-dark m-5 me-0"><inline-svg src="media/icons/duotune/arrows/arr023.svg" /></span>
                  </button>
              </div>
          </div>


          <el-row class="row-bg mt-10" justify="space-evenly" v-show="state.etape == 0">
            <el-col :span="4"></el-col>
            <el-col :span="16">   
              <el-divider content-position="left"><h3 class="text-gray-700" style="margin-top: 8px;">{{$t("Matrice Contrats Stillnetwork")}}</h3></el-divider>

              <div class="m-7 fs-6 mt-15">
                {{$t("Tout d'abord, afin de pouvoir importer vos contrats, veuillez")}} <b><a href="/download/StillOnline_MATRICE_Import_Base_Support.xlsx">{{$t("cliquer ici pour télécharger notre matrice")}}</a></b>.<br><br>
                {{$t('Le fichier Excel téléchargé comporte deux onglets. Le premier "Informations" vous guidera sur la saisie des champs, le second "Contrats" sera votre référentiel de contrats que nous importerons.')}}<br><br>
              </div>

              <el-divider content-position="left" class="mb-15 mt-15"><h3 class="text-gray-700" style="margin-top: 8px;">{{$t("Upload de votre fichier")}}</h3></el-divider>

              <el-upload ref="uploadRef" action="#" :on-change="submitUpload" :show-file-list="false" style="width:100%;" :auto-upload="false">
                <div style="border: 1px dashed rgb(90 90 90); border-radius: 7px;" class="pb-10 btn-upload">
                  <div v-show="!state.loaderUpload">
                    <div class="el-upload__text mt-9 mb-5 text-primary fs-6" style="width: 100%;">
                      {{$t("Veuillez cliquer pour afficher l'explorateur et importer votre fichier.")}}
                    </div>
                    <span class="svg-icon svg-icon-5hx svg-icon-primary m-5">
                      <inline-svg src="media/icons/duotune/files/fil005.svg" />
                    </span>
                  </div>

                  <div v-show="state.loaderUpload" style="width: 100%; height: 100%; text-align: center; padding-top: 100px">
                    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
                    <div class="fs-4 mb-20">{{$t("Vérification de la cohérence de votre fichier")}}</div>
                  </div>

                </div>
                  <template #tip>
                    <div class="el-upload__tip mb-12">
                      {{$t("Attention, seule la matrice Excel fournie ci-dessus peut être importée, veuillez noter que le fichier ne peut être supérieur à 2 MO.")}}
                    </div>
                </template>
              </el-upload>


            </el-col>
            <el-col :span="4"></el-col>
          </el-row>
          <el-row class="row-bg mt-10" justify="space-evenly" v-show="state.etape == 1">
            <el-col :span="24">              
              <el-table :data="state.workbook" table-layout="auto" v-if="state.okList" class="fs-6">

                <el-table-column prop="2" :label="$t('Constructeur')">
                  <template #default="scope">
                    <div v-if="scope.row[17][2][0]" class="text-danger">{{ scope.row[17][2][0] }}</div>
                    <div v-else >{{ scope.row[2] }}</div>
                  </template>
                </el-table-column>

                <el-table-column prop="0" :label="$t('Contrat')">
                  <template #default="scope">
                    <div v-if="scope.row[17][0][0]" class="text-danger">{{ scope.row[17][0][0] }}</div>
                    <div v-else >{{ scope.row[0] }}</div>
                    <div>{{ scope.row[1] }}</div>
                  </template>
                </el-table-column>

                <el-table-column prop="3" :label="$t('Produit')" >
                  <template #default="scope">
                    <div v-if="scope.row[17][3][0]" class="text-danger">{{ scope.row[17][3][0] }}</div>
                    <div v-else >{{ scope.row[3] }}</div>
                    <div v-if="scope.row[17][4][0]" class="text-danger">{{ scope.row[17][4][0] }}</div>
                    <div v-else >{{ scope.row[4] }}</div>
                  </template>
                </el-table-column>

                <el-table-column prop="5" :label="$t('Numéro de Série')" >
                  <template #default="scope">
                    <div v-if="scope.row[17][5][0]" class="text-danger">{{ scope.row[17][5][0] }}</div>
                    <div v-else >{{ scope.row[5] }}</div>
                  </template>
                </el-table-column>

                <el-table-column prop="6" :label="$t('IDs')" >
                  <template #default="scope">
                    <div>{{$t("ID:")}} {{ scope.row[6] ? scope.row[6] : '#NA' }}</div>
                    <div>{{$t("Parent:")}} {{ scope.row[7] ? scope.row[7] : '#NA' }}</div>
                  </template>
                </el-table-column>                  

                <el-table-column prop="8" :label="$t('Serv. Level')" />

                <el-table-column prop="9" :label="$t('Dates')">
                  <template #default="scope">
                    <div v-if="scope.row[17][9][0]" class="text-danger">{{ scope.row[17][9][0] }}</div>
                    <div v-else >{{ excelToDate(scope.row[9]) }}</div>
                    <div v-if="scope.row[17][10][0]" class="text-danger">{{ scope.row[17][10][0] }}</div>
                    <div v-else >{{ excelToDate(scope.row[10]) }}</div>
                  </template>
                </el-table-column>

                <el-table-column prop="11" :label="$t('Site')" >
                  <template #default="scope" class="badge text-dark bt-7 fs-7">{{ scope.row[11] }} {{ scope.row[12] }}<br>{{ scope.row[13] }} {{ scope.row[14] }} {{ scope.row[15] }}</template>
                </el-table-column>

                <el-table-column prop="16" :label="$t('Source')" />

                <el-table-column prop="17" :label="$t('Cohérence')">
                  <template #default="scope">
                    <span v-if="scope.row[18]==0" class="svg-icon svg-icon-1 svg-icon-success me-3"><inline-svg src="media/icons/duotune/arrows/arr016.svg" /></span>
                    <span v-else class="svg-icon svg-icon-1 svg-icon-danger me-3"><inline-svg src="media/icons/duotune/arrows/arr015.svg" /></span>
                  </template>
                </el-table-column>
                
              </el-table>

            </el-col>
          </el-row>


          <el-row class="row-bg mt-10" justify="space-evenly" v-show="state.etape == 2">
            <el-col :span="24">
                <div style="width: 100%; height: 100%; text-align: center; padding-top: 100px">
                  <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
                  <div class="fs-4 mb-20">{{$t("Import de vos données en cours")}}</div>
                </div>
            </el-col>
          </el-row>

        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import * as moment from "moment";
import mAxiosApi from "@/api";
import XLSX from "xlsx";
import { ElNotification } from 'element-plus'
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "contrats",
  components: {
    //
  },
 
  emits: ["refreshRouter"],

  setup(props, { emit }) {

    //moment.default.locale("fr");

    const state = reactive({
      etape: 0,
      workbook: [] as any,
      okList : false,
      totIncoherence : 0 as number,
      lConst : [] as any,
      loaderUpload : false,
    });
    const { t } = useI18n()

    const submitUpload = () => {
      state.loaderUpload = true;
      const file1: any = document.querySelector('input[type=file]');
      const file = file1.files[0];
      const reader = new FileReader();
      
      reader.addEventListener("load", function () {
        //console.log(reader.result);
        
        const data = new Uint8Array(reader.result as ArrayBuffer);
        const zWorkbook = XLSX.read(data, {type: 'array'});
        
        if (zWorkbook['SheetNames'][1]  != "Contrats") {
          alert('erreur');
          state.loaderUpload = false;
          return false;
        }
        
        state.workbook = to_json(zWorkbook)['Contrats'];
        delete state.workbook[0];
        test_coherence();

      }, false);

      if (file) {
        reader.readAsArrayBuffer(file);
      }

    }

    const excelToDate = (excelDate) => {
      const mDate = new Date(Math.round((excelDate - (25567 + 2)) * 86400 * 1000));
      if (isNaN(mDate.getTime())) {
        return '';
      } 
      const convertedDate = mDate.toISOString().split('T')[0];
      return moment.default(convertedDate).format("DD.MM.YYYY");
    }

    const to_json = function to_json(workbook) {
      var result = {};
      workbook.SheetNames.forEach(function(sheetName) {
        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header:1});
        if(roa.length) result[sheetName] = roa;
      });
      return result;
    };


    const test_coherence = () => {
      state.totIncoherence = 0;
      state.workbook = state.workbook.filter(item => item.length > 0);
      let totIncoherence = 0;

      state.workbook.forEach(function(item) {
        item[17] = [] as any;       

        item[17].push({0: item[0] === undefined ? t("N° de contrat vide") : null});  
        item[17].push({0: null});
        item[17].push({0: item[2] === undefined ? t("Aucun constructeur") : null});

        if (item[17][2][0] === null) {
          const allConst: [] = state.lConst.results;
          const filterConst = allConst.filter((it: any) => it.soc_nom_societe.toUpperCase() == item[2].toUpperCase());
          if (filterConst.length == 0) item[17][2][0] = item[2] + " inconnu";
        }

        item[17].push({0: item[3] === undefined ? t("Référence vide") : null});
        item[17].push({0: item[4] === undefined ? t("Aucune description") : null});
        item[17].push({0: item[5] === undefined ? t("N° de série vide") : null});
        item[17].push({0: null});
        item[17].push({0: null});
        item[17].push({0: null});
        item[17].push({0: item[9] === undefined || excelToDate(item[9]) === '' ? t("Date de début vide") : null});
        item[17].push({0: item[10] === undefined || excelToDate(item[10]) === '' ? t("Date de fin vide") : null});

        if (item[9] > item[10]) item[17][10] = {0: 'Début > Fin'}

        item[18] = totDefaut(item[17]);
        totIncoherence = totIncoherence + item[18];

      });

      state.totIncoherence = totIncoherence;
      state.loaderUpload = false;
      state.okList = true;
      state.etape = 1;

    };

    const totDefaut = (mArray) => {
      let test = 0;
      mArray.forEach(function(item) {
        if(item[0]) test += 1;
      });
      // if( test > 0 ) {
        // state.totIncoherence = Number(state.totIncoherence) + Number(test);
      //}
      return test;
    }

    const importContrat = async () => {
      state.etape += 1;
      const contratSelect: any = state.workbook;
      mAxiosApi
        .post("importContrat", { contratSelect })
        .then(() => {

          ElNotification({
            title: t("Succès"),
            message: t("Import de vos contrats réalisé avec succès !"),
            type: 'success',
          })

          emit("refreshRouter" , 1);
        })
        .catch((err) => {
          console.error(err);
        });
    };


    onMounted(async () => {
      state.lConst = await mAxiosApi.prototype.getAxios("/getConstList");
      // console.log('Contructeur', myList);
    });


    return {
      state,
      submitUpload,
      excelToDate,
      test_coherence,
      totDefaut,
      importContrat,
    };
  
  },
});
</script>
