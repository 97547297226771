<template>
<div>
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>

  <transition name="fade">
    <div class="card" v-if="!state.loaderEnabled">
      <div class="card-header border-0 pt-6">
        <button type="button" class="btn btn-sm btn-text-icon btn-primary btn-active-light m-1" style="margin-top: 9px !important; height: 44px;" @click="state.drawerImport = true">
          <span class="svg-icon svg-icon-1 svg-icon-dark">
            <inline-svg src="media/icons/duotune/files/fil022.svg" />
          </span>
          <span>
             {{$t("Importer des contrats / Equipements")}}
          </span>
        </button>
        <SearchAndFilter
          :listInitial="state.initialMyList"
          :filters="tableFilters"
          @returnSearch="state.myList = $event"
          @returnLoadingDatatable="state.loadingDatatable = $event"
          ExcelFilename="Stillnetwork_Contrats"
          widthDecalage="300"
          :ExcelFormat="ExcelFormat"
        ></SearchAndFilter>
      </div>
      <div class="card-body pt-0">
        <div
          v-if="state.myList.length == 0"
          class="
            alert
            bg-light-primary
            alert-primary
            d-flex
            align-items-center
            p-5
            mb-10
          "
        >
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">{{$t("Aucun Contrat")}}</h4>
            <span
              >{{$t("Il n'existe aucun contrat à afficher correspondant à vos critères de recherche.")}}</span
            >
          </div>
        </div>
        <Datatable
          v-if="state.myList && state.myList.length > 0"
          :table-data="state.myList"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          empty-table-text="Aucun contrat à afficher."
          :key="state.loadingDatatable"
        >
          <template v-slot:cell-fsh_contract_number="{ row: data }">
            <div style="margin-left: 15px" class="text-gray-800 w-200px">
              <img :src="imgConst(data.const_seq)"  v-if="imgConst(data.const_seq) && data.const_seq" alt="image" style="width: auto; max-height: 40px; max-width:75px; background-color: #ececec; float: left;margin-right:10px;" />
              <div v-else class="badge text-gray-900" style="width: auto; min-height: 40px; min-width:75px; background-color: #ececec; float: left;margin-right:10px;" >{{ data.constructeur }}</div>
              <div class="badge badge-light-secondary fs-7 text-gray-900 bt-7 fs-5 pt-3">{{$t("N°")}} {{ data.fsh_contract_number }}</div>
            </div>
          </template>
          <template v-slot:cell-cont_libelle="{ row: data }">
              <input
                type="text"
                v-model="data.cont_libelle"
                @change="changeLabel(data)"
                class="form-control form-control-sm form-control-solid w-100"
                style="min-width: 250px;"
                :placeholder="$t('Libellé du contrat')"
              />
          </template>
          <template v-slot:cell-loc_nature="{ row: data }">
            <div v-if="data.cont_x_archive == 1" class="badge w-150px badge-danger mb-1" >
              {{$t("Contrat archivé")}}
            </div>
            <div v-if="data.cont_origine == 'import'" class="badge w-150px badge-primary mb-1" >
              {{$t("Contrat importé")}}
            </div><br>
            <div class="badge w-150px badge-light-dark mb-1" >
              {{ $t(data.loc_nature) }}
            </div>
          </template>

          <template v-slot:cell-fsh_date_debut="{ row: data }">
            <span v-if="test_3_month(data.fsh_date_fin)">
              <div class="badge text-dark w-125px bt-7 fs-7">
                <span v-if="data.fsh_date_debut" class="fs-8">{{$t("Du")}} </span><span v-if="data.fsh_date_debut">{{ formatDate(data.fsh_date_debut) }}<br><br></span><span class="fs-8">{{$t("au")}}</span> {{ formatDate(data.fsh_date_fin) }}
              </div>
            </span>
            <span v-else>
              <div class="badge badge-light-danger w-125px bt-7 fs-7">
                <span v-if="data.fsh_date_debut" class="fs-8">{{$t("Du")}} </span><span v-if="data.fsh_date_debut">{{ formatDate(data.fsh_date_debut) }}<br><br></span><span class="fs-8">{{$t("au")}}</span> {{ formatDate(data.fsh_date_fin) }}
              </div>
            </span>
          </template>
          <template v-slot:cell-cnt_smart_account="{ row: data }">
            <span class="w-150px fs-8" v-if="data.loc_nature == 'Abonnement'">
              <span class="bg-primary"></span>
              <b class="text-primary">{{$t("SmartAccount:")}}</b>
              &nbsp;{{ data.cnt_smart_account }}
            </span>
            <span class="w-150px fs-8" v-else v-html="data.ccoid"></span>
          </template>
          <template v-slot:cell-xliens="{ row: data }">
            <el-dropdown v-if="data.xlienstotal>0">
              <span class="el-dropdown-link">
                <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark w-175px">
                  {{ data.xlienstotal }} {{$t("commande")}}<span v-if="data.xlienstotal>1">{{$t("s")}}</span>
                  &nbsp;<span class="svg-icon svg-icon-1"> <inline-svg src="media/icons/duotune/\arrows/arr072.svg"/> </span>
                </a>
              </span>
              <template #dropdown>
                <el-dropdown-menu v-html="data.xliens">
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <span v-else class="text-danger fs-7 badge badge-light-danger">
              {{$t("Aucune commande liée")}}
              </span>
          </template>
          <template v-slot:cell-code="{ row: data }">
            <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark" @click='affDetail(data)'>
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg src="media/icons/duotune/general/gen004.svg"/> </span>
                {{$t("Détail du contrat")}}
            </a>
          </template>
        </Datatable>
      </div>
    </div>
  </transition>
  <el-drawer v-model="state.drawer" :with-header="false" size="100%" modal-class="modal-transp" v-show="state.contratSelect.fsh_contract_number">
    <div class="card shadow-none" style="width: 100%; height: 100%;">
      <div class="card-header" id="kt_activities_header" style="background: white;">
        
        <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
          <button @click="state.drawer = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Retour")}}</button>
          <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
            {{$t("Détail de votre contrat")}} {{ state.contratSelect.constructeur }} N° {{ state.contratSelect.fsh_contract_number }}
          </h1>
        </div>
        <div class="card-toolbar">
          <button v-if="state.contratSelect && state.contratSelect.fsh_contract_number && posIndex()>0" type="button" class="btn btn-sm btn-text-icon btn-active-light-primary me-n0" @click="affPrecedent()">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr021.svg" />
            </span>
            <span>
              {{$t("Contrat précédent")}}
            </span>
          </button>
          <button v-if="state.contratSelect && state.contratSelect.fsh_contract_number && posIndex() < state.myList.length-1" type="button" class="btn btn-sm btn-text-icon btn-active-light-primary me-n5" @click="affSUivant()">
            <span>
              {{$t("Contrat suivant")}}
            </span>
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr024.svg" />
            </span>
          </button>
        </div>
      </div>
      <div class="card-body position-relative" id="kt_activities_body" style="overflow: auto !important;  height: calc(100vh - 100px); background: url(media/still/OLDEbackg.jpg) no-repeat center center fixed;  background-size: cover;">
          <DetailContrat @changeContrat="changeContrat($event)" :zContrat="state.contratSelect" :key="state.contratSelect.fsh_contract_number" card-classes="mb-12"></DetailContrat>
      </div>
    </div>
  </el-drawer>
  <el-drawer direction='ttb' v-model="state.drawerImport" :with-header="false" size="100%" modal-class="modal-transp">
    <div class="card shadow-none" style="width: 100%; height: 100%;">
      <div class="card-header" id="kt_activities_header" style="background: white;">
        
        <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
          <button @click="state.drawerImport = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Annuler l'import")}}</button>
          <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
            {{$t("Importer des contrat de support")}}
          </h1>
        </div>
      </div>
      <div class="card-body position-relative" id="kt_activities_body" style="    overflow: auto !important;  height: calc(100vh - 100px); background: url(media/still/OLDEbackg.jpg) no-repeat center center fixed;  background-size: cover;">
        <ImportContrat v-if="state.drawerImport" @refreshRouter="refreshRouter($event)" card-classes="mb-12"></ImportContrat>
      </div>
    </div>
  </el-drawer>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import DetailContrat from "@/views/still/contrats/DetailContrat.vue";
import ImportContrat from "@/views/still/contrats/ImportContrat.vue";
import { useI18n } from "vue-i18n";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    DetailContrat,
    ImportContrat,
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {
    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      drawerImport: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
      contratSelect: {},
    });

    const tableHeader = ref([
      {
        name: "Contrat",
        key: "fsh_contract_number",
        sortable: true,
      },
      {
        name: "Type",
        key: "loc_nature",
        sortable: true,
      },
      {
        name: "Libellé du contrat",
        key: "cont_libelle",
        sortable: true,
      },
      {
        name: "Période",
        key: "fsh_date_debut",
        sortable: true,
      },
      /*
      {
        name: "SmartAccount",
        key: "cnt_smart_account",
        sortable: true,
      },
      */
      {
        name: "Commandes",
        key: "xliens",
        sortable: true,
      },
      {
        name: "Actions",
        key: "code",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Constructeur",
        key: "constructeur",
        label: "constructeur",
      },
      {
        name: "Type",
        key: "loc_nature",
        label: "loc_nature",
      },
    ]);

    const ExcelFormat = ref({
      "Contrat": "fsh_contract_number",
      "Type": "loc_nature",  
      "Libellé du contrat": "cont_libelle",
      "Début": "fsh_date_debut",
      "Fin": "fsh_date_fin",
      "Commandes": "xlienssmart",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_3_month = (d) => {
      const currentDate = moment.default().add(3, 'M');
      return moment.default(d) > currentDate;
    }

    const refreshRouter = () => {
      emit("refreshRouter" , 1);
    }

    const passLng = (d) => {
      state.myList.forEach(
        function(item: any){
          if (item.displayrow == 1) {
            item.displayrow = 2
          } else {
            item.displayrow = 1
          }
        }
      );
    }

    const changeLabel = async (cont) => {
      mAxiosApi
        .post("updateContLabel", cont)
        .then(({ data }) => {
          // console.log(data["results"]);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const affDetail = async (data) => {
      // console.log('Detail',data);
      state.contratSelect = data;
      state.drawer = true;
    };

    const affSUivant = async () => {
      if (posIndex()<state.myList.length) {
        state.contratSelect = state.myList[posIndex()+1];
        // await affDetail(state.myList[posIndex()+1]);
      }
    };

    const affPrecedent = async () => {
      if (posIndex()>0) {
        state.contratSelect = state.myList[posIndex()-1];
        // await affDetail(state.myList[posIndex()-1]);
      }
    };

    const posIndex = () => {
      const mlContrat: any = state.myList;
      const indexCont = mlContrat.findIndex(function (data: any) {
        const mContrat: any = state.contratSelect;
        return data.fsh_contract_number == mContrat.fsh_contract_number;
      });
      return indexCont;
    };

    const imgConst = (idConst) => {
      const storedConst =  localStorage.getItem('storedConst');
      const storedConstJson = storedConst ? JSON.parse(storedConst) : '';
      const socConst: any = storedConstJson.filter(function (data: any) {
        return data.soc_seq == idConst;
      })[0]
      if (socConst) {
        return  `data:image/jpg;base64,${socConst.soc_logo}`;
      } else {
        return ''
      }
      
    };

    const changeContrat = (ev) => {
      // console.log('Passe ici ', ev);

      const index = state.myList.findIndex(
        (object: any) => object.code === ev.code
      );
      const myList: any = state.myList;
      myList[index]['cont_x_archive'] = ev.cont_x_archive;
      // console.log('INDEX', index);

    }

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Gestion des Contrats de support & Abonnements", []);

      let myList = await mAxiosApi.prototype.getAxios("/getContratSupport");
      state.initialMyList = myList.results;
      state.myList = myList.results;      
      console.log(state.myList);
      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      test_3_month,
      affDetail,
      tableFilters,
      ExcelFormat,
      imgConst,
      passLng,
      changeLabel,
      affSUivant,
      affPrecedent,
      posIndex,
      refreshRouter,
      changeContrat,
    };
  },
});
</script>

<style>
.el-dropdown-menu__item:hover {
  cursor: pointer;
  background-color: #e4e7ed !important;
}
</style>